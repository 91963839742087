//Import library's
import React, { useEffect } from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import {
  CardActionArea,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";

//Import CSS
import "../Styles/home.css";
import "../Styles/common.css";
import { Carousel } from "react-bootstrap";

const DashBoard = () => {
  const navigate = useNavigate();
  const moviedata = useSelector((state) => state.movie.movieData);
  const sectionData = useSelector((state) => state.movie.sectionData);
  const sliderData = useSelector((state) => state.movie.sliderData);

  const [itemHeight, setItemHeight] = useState(860);
  const [selectedDay, setSelectedDay] = useState("");
  const [filteredMovies, setFilteredMovies] = useState([]);

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    setFilteredMovies(moviedata);
  }, [moviedata]);

  const handleDayChange = (event) => {
    const selectedDayValue = event.target.value;
    navigate(`/film-by-day/${encodeURIComponent(selectedDayValue)}`);
  };

  const sanitizeHTML = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText;
  };

  const handleNavigation = (index) => {
    navigate("movie-details/" + `${index}`);
  };

  const handleNavigateToFood = () => {
    navigate("/food");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 767) {
        setItemHeight(350);
      } else {
        setItemHeight(550);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const createUrl = (title) => {
    return encodeURIComponent(title);
  };
  return (
    <div className="dashboard">
      {/* <div
        id="carouselExampleFade"
        className="carousel slide carousel-fade"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {sliderData?.data?.map((item, index) => {
            return (
              <div
                className={`carousel-item ${index === 0 ? 'active' : ''}`}
                key={item.id}
              >
                <img
                  key={item.id}
                  src={item.image_url}
                  className="d-block m-auto"
                  alt={`Slide ${item.id}`}
                  style={{
                    maxWidth: '100%',
                    objectFit: 'fill',
                    maxHeight: itemHeight,
                  }}
                />
              </div>
            )
          })}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleFade"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div> */}

      <Carousel>
        {sliderData?.data?.map((item, index) => {
          return (
            <Carousel.Item interval={3000}>
              <img
                key={item.id}
                src={item.image_url}
                className="d-block m-auto"
                alt={`Slide ${item.id}`}
                style={{
                  maxWidth: "100%",
                  objectFit: "fill",
                  maxHeight: itemHeight,
                }}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>

      <div className="d-flex align-items-center mt-4">
        <div className="d-flex align-items-center mx-auto store-view">
          <div className="store-text-view">
            <h6 className="text-white pc-text-view">
              DOWNLOAD NOW TO DINE-IN:
            </h6>
            <p className="text-white pc-text-view">
              You can only order food and drink to your seat through our App
            </p>
          </div>

          <div className="mobile-text-view">
            <div>
              <span className="text-white">DOWNLOAD NOW TO DINE-IN:</span>
            </div>
            <span className="text-white">
              You can only order food and drink to your seat through our App
            </span>
          </div>

          <div className="d-flex align-items-center gap-3">
            <div>
              <span>
                <a href="https://www.apple.com/app-store/" target="_blank">
                  <img
                    alt="apple-store"
                    src={require("../Assets/Images/store-apple.png")}
                    className="app-store-image"
                  />
                </a>
              </span>
            </div>
            <div>
              <span>
                <a href="https://play.google.com/store" target="_blank">
                  <img
                    alt="android-google-play"
                    src={require("../Assets/Images/android.png")}
                    className="google-play-image"
                  />
                </a>
              </span>
            </div>
          </div>
        </div>

        <div className="container search-view">
          <select
            name="movies"
            id="movies"
            onChange={(e) => handleNavigation(e.target.value)}
            className="form-select"
            aria-label="Default select example"
          >
            <option value="">Select by movie</option>
            {moviedata?.map((movieItem, index) => (
              <option
                key={`movielist-${index}`}
                value={`${movieItem.ScheduledFilmId}/${createUrl(
                  movieItem.info.Title
                )}`}
                className=""
              >
                {movieItem.info.Title}
              </option>
            ))}
          </select>
          <select
            value={selectedDay}
            onChange={(e) => handleDayChange(e)}
            id="days"
            className="form-select"
            aria-label="Default select example"
          >
            <option value="">Select by day</option>
            {days.map((day) => (
              <option key={`${day}`} value={day}>
                {day}
              </option>
            ))}
          </select>
          {selectedDay !== "" && (
            <div>
              <img
                alt="clear"
                src={require("../Assets/Images/cross.png")}
                height={30}
                width={30}
                onClick={() => {
                  setSelectedDay("");
                  setFilteredMovies(moviedata);
                }}
              />
            </div>
          )}
        </div>
      </div>

      <Container className="mb-3 mt-2">
        {selectedDay && (
          <h4 className="text-white">Films showing on {selectedDay}</h4>
        )}
        <Grid container spacing={2}>
          {filteredMovies?.map((movieItem, index) => {
            return (
              <Grid item xs={6} md={4} lg={3} sm={6} key={`movie_${index}`}>
                <Card
                  onClick={() =>
                    handleNavigation(
                      `${movieItem.ScheduledFilmId}/${createUrl(
                        movieItem.info.Title
                      )}`
                    )
                  }
                >
                  <CardActionArea>
                    <CardMedia
                      className="responsive-img"
                      component="img"
                      image={
                        movieItem.info.GraphicUrl
                          ? movieItem.info.GraphicUrl
                          : require("../Assets/Images/movie-poster-design.png")
                      }
                      alt={movieItem.info.Title}
                    />
                    <CardContent className="card-content">
                      <Typography
                        variant="h5"
                        component="div"
                        className="fs-6 card-content-text"
                      >
                        {movieItem.info.Title}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>

        <div className="container mt-5">
          <div
            className="responsive-container"
            style={{
              backgroundImage: `url(${sectionData?.food_offer?.image_path})`,
            }}
            onClick={handleNavigateToFood}
          >
            <div className="content">
              <p className="special-text">
                {sectionData?.food_offer?.pre_title}
              </p>
              <p className="middle-text">{sectionData?.food_offer?.title}</p>
              <p className="bottom-text">
                {sectionData?.food_offer?.sub_title}
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DashBoard;

//do not delete

{
  /* <div className="full-width-carousel" style={{ height: itemHeight }}>
        <MDBCarousel showControls fade className="custom-carousel">
          {sliderData.data?.map((item) => (
            <MDBCarouselItem
              key={item.id}
              src={item.image_url}
              alt={`Slide ${item.id}`}
              style={{
                width: '100%',
                objectFit: 'fill',
                height: itemHeight,
              }}
            />
          ))}
        </MDBCarousel>
      </div> */
}

{
  /* <div className="container my-5">
          <h2 className="heading fw-semibold text-center">About Fomo</h2>
          <div className="mt-1">
            <h4 className="text-white my-2">
              {sectionData?.about_fomo?.title}
            </h4>
            <div
              className="text-white description-text"
              dangerouslySetInnerHTML={{
                __html: sanitizeHTML(sectionData?.about_fomo?.content),
              }}
            ></div>
          </div>
        </div> */
}

{
  /* <h4 className="container text-white my-3">Spotted Last week</h4>
        <div className="container scrollable-container d-flex justify-content-start gap-4">
          {sectionData.spotted_week?.map((item) => {
            return (
              <div
                className="d-flex flex-column align-items-center gap-0 spoted-main-view"
                key={`spot${item.id}`}
              >
                <div className="spooted-view">
                  <div className="">{item.name.charAt(0)}</div>
                </div>
                <div className="spooted-view-text">{item.name}</div>
              </div>
            )
          })}
        </div> */
}
