import React, { useEffect, useState } from 'react'
import { clientService } from '../../utils/Service'
import '../../Styles/booking.css'
import { Card, Col, Row } from 'react-bootstrap'
import QRCode from 'qrcode.react'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import { Skeleton } from '@mui/material'

const Booking = () => {
  const [loader, setLoader] = useState(false)
  const [bookingList, setBookingList] = useState([])
  const [currentPage, setCurrentPage] = useState(0)

  const itemsPerPage = 12
  const pageCount = Math.ceil((bookingList?.length || 0) / itemsPerPage)
  const offset = currentPage * itemsPerPage
  const currentPageData = (bookingList ?? []).slice(
    offset,
    offset + itemsPerPage,
  )

  useEffect(() => {
    getCardDetails()
  }, [])

  const getCardDetails = async () => {
    setLoader(true)
    try {
      const res = await clientService.get('front/booking-list')
      setBookingList(res?.data?.data)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoader(false)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="container booking-details-container">
      {bookingList?.length <= 0 ? (
        <div className="no-record-view">
          <h4 className="text-white text-center">No record Found</h4>
        </div>
      ) : (
        <>
          <h4 className="text-white text-center my-3">Booking</h4>
          <div className="">
            {loader ? (
              <div className="d-flex justify-content-center flex-wrap gap-4">
                {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((_, index) => (
                  <div key={`skeleton_${index}`} className="">
                    <div className="">
                      <div
                        bg={'dark'}
                        key={'dark'}
                        text={'white'}
                        style={{ backgroundColor: 'grey', borderRadius: 5 }}
                        className="mb-2"
                        xs={12}
                        md={4}
                        lg={3}
                        sm={12}
                      >
                        <Skeleton
                          variant="rounded"
                          width={300}
                          height={300}
                          animation="wave"
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="d-flex justify-content-center flex-wrap gap-4">
                {currentPageData &&
                  currentPageData?.map((item) => {
                    return (
                      <div key={`card_id${item.id}`} className="">
                        <div className="">
                          <Card
                            bg={'dark'}
                            key={'dark'}
                            text={'white'}
                            style={{ borderRadius: 5 }}
                            className="mb-2 booking-history-card"
                            xs={12}
                            md={4}
                            lg={3}
                            sm={12}
                          >
                            <Card.Header style={{ fontSize: 20 }}>
                              {item?.booking_info?.Tickets[0]?.MovieName}
                            </Card.Header>
                            <Card.Body>
                              <Card.Text>
                                <Row className="">
                                  <Col>
                                    <div className="text-white">
                                      {`Date & Time :
                               ${moment(item.session_date).format(
                                 'YYYY-MM-DD hh:mm',
                               )}`}
                                    </div>

                                    {/* <div className="text-white mt-4">
                                      Screen Name:
                                    </div> */}

                                    <div className="text-white mt-4">
                                      Seats :
                                      {item?.booking_info.Tickets?.map(
                                        (sit) =>
                                          ` ${sit.SeatRowId}${sit.SeatNumber}`,
                                      ).join(', ')}
                                    </div>
                                    <div className="text-white mt-4">
                                      Booking Id : {item?.vista_booking_id}
                                    </div>
                                  </Col>
                                </Row>
                              </Card.Text>
                              <Row className="box-shadow mt-4">
                                <Col style={{ textAlign: 'center' }}>
                                  <QRCode
                                    value={item?.vista_booking_id}
                                    style={{ height: 70, width: 70 }}
                                  />
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    )
                  })}
              </div>
            )}

            {bookingList?.length > itemsPerPage && (
              <div className="d-flex justify-content-center">
                <ReactPaginate
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={(selected) => setCurrentPage(selected.selected)}
                  containerClassName={'pagination'}
                  activeClassName={'active-page'}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default Booking

// const deleteCard = async (id) => {
//     const res = await clientService.post('front/card/delete', {
//       card_id: id,
//     })
//     getCardDetails()
//   }

//   const linkNewCard = async () => {
//     const res = await clientService.get('front/card/session')
//     if (res.data.status) {
//       window.location.href = res.data.data.links[1].href
//     } else {
//       alert('Please try again')
//     }
//   }

{
  /* <Col xs={5}>
                            <img
                              alt="movie-poster"
                              // src={movieData?.info?.GraphicUrl}
                              height={150}
                              width={100}
                            ></img>
                          </Col> */
}
