import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment'
import { Col, Row } from 'react-bootstrap'
import '../Styles/movieDetail.css'
import BookingDetails from './BookingDetails'

const FilmByDay = () => {
  const { index } = useParams()
  const navigate = useNavigate()

  const handleBackClick = () => {
    navigate(-1)
  }

  const moviedata = useSelector((state) => state.movie.movieData)
  const movieDetails = useSelector((state) => state.movie.movieData).filter(
    (item) => item.ScheduledFilmId == index,
  )[0]

  const selectedDay = index ? decodeURIComponent(index) : null

  const [pageState, setPageState] = useState({
    selectedDate: new Date(),
    selectedTime: null,
    selectedSeat: 1,
    selectedCategory: null,
    ticketInfo: [],
    seatsList: [...Array(10).keys()],
    showSeating: false,
    sessionId: '',
    ScreenName: '',
    ticketTypeCode: '',
    tickets: {},
  })

  const { selectedTime, showSeating } = pageState

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const filteredData = moviedata.filter((movieItem) => {
    const showTimes = movieItem.showTimes
    const ss = showTimes.filter((showTime) => {
      const day = moment(showTime.time).format('dddd')
      return day === selectedDay
    })

    return ss.length > 0
  })

  const handleTimeSelection = (showtimes) => {
    setPageState({
      ...pageState,
      selectedTime: showtimes.time,
      sessionId: showtimes.sessionId,
      ScreenName: showtimes.ScreenName,
      showSeating: true,
    })
  }

  const handleNavigation = (index) => {
    navigate('/movie-details/' + `${index}`)
  }

  const createUrl = (title) => {
    return encodeURIComponent(title)
  }

  const sanitizeHTML = (html) => {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = html
    return tempDiv.textContent || tempDiv.innerText
  }

  if (showSeating) {
    return <BookingDetails movieDetails={movieDetails} pageState={pageState} />
  }

  return (
    <div className="container">
      <div className="d-flex align-items-center gap-2 my-2">
        <img
          alt="arrow"
          src={require('../Assets/Images/left-white-arrow.png')}
          className="previous-btn"
          onClick={handleBackClick}
          height={25}
          width={25}
          style={{ cursor: 'pointer' }}
        />
        <h4 className="text-white mt-2 mx-2">Films showing on {selectedDay}</h4>
      </div>

      {filteredData.map((movie, index) => {
        const renderedDates = []

        return (
          <div key={index} className="text-white my-3">
            <Row style={{ backgroundColor: '#4545457e', padding: 10 }}>
              <Col lg={2} xs={5} md={4} sm={3}>
                <img
                  src={
                    movie.info.GraphicUrl
                      ? movie.info.GraphicUrl
                      : require('../Assets/Images/movie-poster-design.png')
                  }
                  className="movie-pos-style"
                />
              </Col>
              <Col lg={10} xs={7} md={8} sm={9}>
                <div className="text-white mb-2 fw-medium">
                  {movie.info.Title}
                </div>

                <div
                  className="text-white description-text-one movie-synopsis"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHTML(movie?.info?.Synopsis),
                  }}
                ></div>

                <p
                  style={{
                    color: '#d22b86',
                    marginBottom: 10,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    handleNavigation(
                      `${movie.ScheduledFilmId}/${createUrl(movie.info.Title)}`,
                    )
                  }
                >
                  More
                </p>

                {movie.showTimes
                  .filter(
                    (showTime) =>
                      moment(showTime.time).format('dddd') === selectedDay,
                  )
                  .sort(
                    (a, b) =>
                      moment(a.time).valueOf() - moment(b.time).valueOf(),
                  )
                  .map((filteredDate, dateIndex) => {
                    const uniqueDate = moment(filteredDate.time).format(
                      'dddd Do MMMM ',
                    )
                    if (renderedDates.includes(uniqueDate)) {
                      return null
                    }
                    renderedDates.push(uniqueDate)

                    return (
                      <div key={`Date-${dateIndex}`}>
                        <h6 className="text-white date-text">{uniqueDate}</h6>
                        <div className="d-flex flex-wrap">
                          {movie.showTimes
                            .filter(
                              (showtimes) =>
                                moment(showtimes.time).format(
                                  'dddd Do MMMM ',
                                ) === uniqueDate,
                            )
                            .map((showtimes, showTimeIndex) => (
                              <div key={showTimeIndex}>
                                <div
                                  className={`time-button ${
                                    showtimes.time === selectedTime
                                      ? 'selected-time'
                                      : 'not-selected-time'
                                  }`}
                                  onClick={() => handleTimeSelection(showtimes)}
                                >
                                  <div className="time-button-content">
                                    {moment(showtimes.time).format('HH:mm')}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    )
                  })}
              </Col>
            </Row>
          </div>
        )
      })}

      {!filteredData.length === 0 && (
        <div className="text-white text-center my-5">
          No movies available for the {selectedDay}.
        </div>
      )}
    </div>
  )
}

export default FilmByDay
