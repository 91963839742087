/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
//Import Library's
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

//Import CSS
import "../Styles/seats.css";
import "../Styles/bookingDetails.css";
import "react-calendar/dist/Calendar.css";

//Import Slice
import { useDispatch, useSelector } from "react-redux";
import { setShowLogin } from "../store/slices/authSlice";

//Import Components
import Loader from "../components/Loader";
import { clientService } from "../utils/Service";
import SeatMatrix from "../components/SeatMatrix";
import moment from "moment/moment";
import TicketSelection from "./TicketSelection";

const BookingDetails = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { movieDetails, pageState } = props;
  const [finalTickets, setFinalTickets] = useState([[], []]);
  const [bookingFee, setBookingFee] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const isUserLoggedIn = useSelector((state) => state.auth.isLoggedn);
  const {
    selectedTime,
    selectedSeat,
    selectedCategory,
    sessionId,
    ticketTypeCode,
    ScreenName,
    price,
  } = pageState;

  const [seatData, setSeatData] = useState({});
  const [showTicketType, setShowTicketType] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [ticketTypes, setTicketTypes] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [selectedSeats, setSelectedSeats] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showLoader, showModal]);

  const getSeatPlan = async () => {
    try {
      let response = await clientService.get(
        `front/film/site-plan/${sessionId}`
      );
      let selectedSeats = {};
      response.data.data.SeatLayoutData.Areas.forEach((area) => {
        area.Rows.forEach((row) => {
          selectedSeats[row.PhysicalName] = [];
          row.Seats.forEach((seat) => {
            selectedSeats[row.PhysicalName][seat.Position.ColumnIndex] = {
              ...seat,
              AreaCategoryCode: area.AreaCategoryCode,
            };
          });
        });
      });
      setSeatData(selectedSeats);
    } catch (e) {
      console.log(e);
    }
  };

  const getCategory = async () => {
    const res = await clientService.get(`front/film/ticket/${sessionId}`);
    setTicketTypes(res?.data?.data?.Tickets);
  };

  const validateSelection = () => {
    let selectedCount = 0;
    for (const rowName in seatData) {
      for (const seat of seatData[rowName]) {
        if (seat?.OriginalStatus === 99) {
          selectedCount++;
        }
      }
    }
    return selectedCount >= 1;
  };

  const doBooking = async () => {
    setShowLoader(true);
    setShowTicketType(false);
    const seats = [];
    const typesPrice = {};
    const typesQty = {};

    finalTickets.forEach((item) => {
      item.forEach((seat) => {
        seats.push({
          AreaCategoryCode: seat.AreaCategoryCode,
          AreaNumber: seat.Position.AreaNumber,
          RowIndex: seat.Position.RowIndex,
          ColumnIndex: seat.Position.ColumnIndex,
        });

        typesPrice[seat.TicketTypeCode] = seat.price;
        typesQty[seat.TicketTypeCode] = typesQty[seat.TicketTypeCode]
          ? typesQty[seat.TicketTypeCode] + 1
          : 1;
      });
    });

    const types = [];
    Object.keys(typesPrice).forEach((item) => {
      types.push({
        TicketTypeCode: item,
        Qty: typesQty[item],
        PriceInCents: typesPrice[item],
        BookingFeeOverride: null,
      });
    });

    const data = {
      tickets: {
        CinemaId: "9999",
        SessionId: sessionId,
        TicketTypes: types,
        BookingFeeOverride: null,
        ReturnOrder: true,
        ReturnDiscountInfo: true,
        SelectedSeats: seats,
      },
    };

    const response = await clientService.post(`front/web/order`, data);
    if (response?.data?.statusCode === "200") {
      if (response?.data?.data?.links) {
        window.location.href = response?.data?.data?.links[1].href;
        setShowLoader(false);
        setShowModal(false);
      } else {
        setShowLoader(false);
        setShowModal(false);
      }
      navigate(`/order-details/${response?.data?.data.vista_booking_id}`);
    } else {
      setShowLoader(false);
      setShowModal(false);
      alert("Please try again");
    }
  };

  const handleTicketBooking = () => {
    if (isUserLoggedIn) {
      setShowModal(true);
    } else {
      dispatch(setShowLogin(true));
    }
  };

  useEffect(() => {
    if (sessionId) {
      getSeatPlan();
      getCategory();
    }
  }, [sessionId]);

  const selectSeat = (row, index) => {
    if (seatData[row][index]?.OriginalStatus === 99) {
      let _seatData = { ...seatData };
      _seatData[row][index].OriginalStatus = 0;
      setSeatData(_seatData);
    } else {
      let selectedCount = 0;
      for (const rowName in seatData) {
        for (const seat of seatData[rowName]) {
          if (seat?.OriginalStatus === 99) {
            selectedCount++;
          }
        }
      }
      let _seatData = { ...seatData };
      _seatData[row][index].OriginalStatus = 99;
      setSeatData(_seatData);
    }
  };

  const handleCancel = () => {
    setSelectedSeats([]);
    setShowModal(false);
  };

  const updateBookingFee = (fee) => {
    setBookingFee(fee);
  };

  return (
    <>
      {showLoader ? (
        <div className="loader-container">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              className="spinner-border text-light text-center"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
            <br />
            <div className="text-white">
              Please wait while we book your seats. Thank you
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid white-bg align-items-center justify-content-center h-100 seat-plan-view">
          <div className="">
            {/* <div onClick={() => navigate(-1)}>back</div> */}
            <div className="sit-view">
              <p className="fs-3 text text-center">
                {movieDetails?.info?.Title}
              </p>
              {seatData["A"] ? (
                <SeatMatrix
                  seatData={seatData}
                  selectSeat={selectSeat}
                  ScreenName={ScreenName}
                  movieDetails={movieDetails}
                />
              ) : (
                <Loader />
              )}
            </div>
            <div className="">
              <div className="container mx-auto d-flex align-item-center justify-content-center flex-column">
                <div className="d-flex flex-wrap flex-row txt gap-2">
                  <div className="mx-4">
                    <img
                      src={require("../Assets/Images/regularSeat.png")}
                      height={25}
                      width={25}
                      alt=""
                    ></img>{" "}
                    &nbsp;&nbsp; Standard
                  </div>
                  <div className="mx-4">
                    <img
                      src={require("../Assets/Images/reclinerSeat.png")}
                      height={25}
                      width={25}
                      alt=""
                    ></img>{" "}
                    &nbsp;&nbsp; Recliner
                  </div>
                  <div className="mx-4">
                    <img
                      src={require("../Assets/Images/available-right.png")}
                      height={30}
                      width={30}
                      alt=""
                    ></img>
                    <img
                      src={require("../Assets/Images/available-left.png")}
                      height={30}
                      width={30}
                      alt=""
                    ></img>
                    &nbsp;&nbsp;Share Table Seats
                  </div>
                  <div className="mx-4">
                    <img
                      src={require("../Assets/Images/booked-squre.png")}
                      height={25}
                      width={25}
                      alt=""
                    ></img>
                    &nbsp;&nbsp; Available
                  </div>

                  <div className="mx-4">
                    <img
                      // src={require('../Assets/Images/available.png')}
                      src={require("../Assets/Images/newBookedGuied-Img.png")}
                      height={25}
                      width={25}
                      alt=""
                    ></img>
                    &nbsp;&nbsp; Booked
                  </div>
                  <div className="mx-4">
                    <img
                      src={require("../Assets/Images/Selected.png")}
                      height={25}
                      width={25}
                      alt=""
                    ></img>
                    &nbsp;&nbsp; Selected
                  </div>
                </div>
              </div>
            </div>

            <div className="my-5 justify-content-center d-flex">
              {validateSelection() && (
                <>
                  <button
                    className="button"
                    onClick={() => setShowTicketType(true)}
                  >
                    Book Ticket
                  </button>
                  <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    backdrop={false}
                    className="model"
                    centered={true}
                  >
                    <Modal.Header closeButton onHide={handleCancel}>
                      <Modal.Title>Booking Summary</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <p className="">
                        Movie Name : {movieDetails?.info?.Title}
                      </p>
                      <p>Selected Seats : {selectedSeats.join(", ")}</p>
                      <p>
                        Date & Time :{" "}
                        {moment(selectedTime).format("ddd Do MMM LT")}
                      </p>
                      <p>Ticket Price : ${(totalPrice / 100).toFixed(2)}</p>
                      <p>
                        Booking Fee : $
                        {typeof bookingFee === "number"
                          ? bookingFee.toFixed(2)
                          : 0}
                      </p>
                      <p>
                        Total Price : $
                        {(
                          Number(totalPrice) / 100 +
                          Number(bookingFee)
                        ).toFixed(2)}
                      </p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        className="modal-button"
                        onClick={() => {
                          setSelectedSeats([]);
                          setShowModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        className="modal-button"
                        onClick={doBooking}
                      >
                        Proceed
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              )}
            </div>
          </div>
          <div className="notice">
            <ul>
              <li>*Recliner seats cost $1 more than standard seats.</li>
              <li>
                *Concession price is available for students, pensioners,
                seniors, children under 15 and FoMo members.
              </li>
              <li>
                *Make sure you choose “Share Table Seats” for 2 seats together.
              </li>
              <li>
                *Partner and Concession IDs need to be shown with tickets for
                entry to FoMo. *Booking fee is $1.75/ticket.
              </li>
              <li>
                {" "}
                *Wheelchair spaces are available, please book on 9000 7440 to
                reserve seat(s) with bespoke table.
              </li>
            </ul>
          </div>
        </div>
      )}

      {showTicketType && (
        <Offcanvas
          placement="end"
          show={showTicketType}
          onHide={() => {
            setShowTicketType(false);
          }}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Choose your tickets</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ background: "#000" }}>
            <TicketSelection
              ticketTypes={ticketTypes}
              setFinalTickets={(tickets) => {
                if (isUserLoggedIn) {
                  let _selectedTickets = [];
                  let total = 0;
                  tickets.forEach((item) => {
                    item.forEach((col) => {
                      _selectedTickets.push(`${col.rowId}${col.Id}`);
                      total += col.price;
                    });
                  });
                  // updateBookingFee(tickets)
                  setSelectedSeats(_selectedTickets);
                  setTotalPrice(total);
                  setFinalTickets(tickets);
                  setShowModal(true);
                } else {
                  dispatch(setShowLogin(true));
                }
              }}
              setBookingFee={updateBookingFee}
              seatData={seatData}
            />
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
};

export default BookingDetails;

//Do Not DELETE

// const selectSeat = (row, index) => {
//   let _seatData = { ...seatData }
//   let rowData = [..._seatData[row]]

//   rowData[index] = {
//     ...rowData[index],
//     OriginalStatus: rowData[index]?.OriginalStatus === 0 ? 2 : 0,
//   }
//   _seatData[row] = rowData
//   setSeatData(_seatData)
// }
