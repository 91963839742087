import React, { useEffect, useState } from 'react'
import { clientService } from '../../utils/Service'

const Disclaimer = () => {
  const [content, setContent] = useState('')
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    getPrivacyPage()
  }, [])

  const getPrivacyPage = async () => {
    setLoader(true)
    const res = await clientService.post('front/content', {
      title: 'Disclaimer',
    })
    setContent(res.data.data.content)
    setLoader(false)
  }
  return (
    <div className="container text-white">
      {loader ? (
        <>
          <div className="aboutusloder-view">
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>{' '}
        </>
      ) : (
        <div
          className="mt-4"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      )}
    </div>
  )
}

export default Disclaimer
