/* eslint-disable no-useless-concat */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
//Import library's
import React, { useEffect } from 'react'
import Card from '@mui/material/Card'
import { useNavigate } from 'react-router-dom'
import {
  CardActionArea,
  CardMedia,
  Container,
  Grid,
  Typography,
} from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { useDispatch, useSelector } from 'react-redux'

//Import CSS
import '../Styles/home.css'
import '../Styles/common.css'

const CoomingSoon = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const moviedata = useSelector((state) => state.movie.movieData)

  const comingSoonMovies = moviedata.filter(
    (movieItem) => movieItem.info.IsComingSoon === true,
  )

  const handleNavigation = (index) => {
    navigate('/movie-details/' + `${index}`)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [navigate])

  const createUrl = (title) => {
    return encodeURIComponent(title)
  }

  return (
    <div className="dashboard">
      <Container className="mb-4">
        <Grid container>
          <Grid item className="mb-2"></Grid>
        </Grid>
        <Grid container spacing={2}>
          {comingSoonMovies?.map((movieItem, index) => {
            return (
              <Grid item xs={6} md={4} lg={3} sm={6} key={`movie_${index}`}>
                <Card
                  onClick={() =>
                    handleNavigation(
                      `${movieItem.ScheduledFilmId}/${createUrl(
                        movieItem.info.Title,
                      )}`,
                    )
                  }
                >
                  <CardActionArea>
                    <CardMedia
                      className="responsive-img"
                      component="img"
                      height="410"
                      image={
                        movieItem.info.GraphicUrl
                          ? movieItem.info.GraphicUrl
                          : require('../Assets/Images/movie-poster-design.png')
                      }
                      alt="green iguana"
                    />
                    <CardContent className="card-content">
                      <Typography
                        variant="h5"
                        component="div"
                        className="fs-6 card-content-text"
                      >
                        {movieItem.info.Title}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </div>
  )
}

export default CoomingSoon
