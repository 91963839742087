/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
//librarys
import React, { useEffect, useState } from 'react'
import { auth } from './utils/firebaseApp'

//CSS
import './index.css'
import './Styles/common.css'
import 'react-calendar/dist/Calendar.css'

//react-redux things
import { useDispatch, useSelector } from 'react-redux'
import { setLoggedInUser } from './store/slices/authSlice'
import {
  getMovieData,
  getSectionData,
  getSliderData,
} from './store/slices/movieSlice'
import { getFoodData } from './store/slices/foodSlice'

//components
import Header from './components/Header'
import Footer from './components/Footer'
import Navigation from './Navigation/Navigation'
import MovieContext from './Contexts/MovieContext'
import DashBoardPreview from './pages/DashBoardPreview'

const App = () => {
  const dispatch = useDispatch()
  const foodLoading = useSelector((state) => state.food.isLoading)
  const movieLoading = useSelector((state) => state.movie.isLoading)

  const getAppData = async () => {
    dispatch(getMovieData())
    dispatch(getFoodData())
    dispatch(getSectionData())
    dispatch(getSliderData())
  }

  const getCurrentUser = () => {
    let user = auth.currentUser
    if (user) {
      dispatch(setLoggedInUser(user))
    }
  }

  useEffect(() => {
    getAppData()
    getCurrentUser()
  }, [])

  return (
    <div className="app">
      <Header />
      <div className="main">
        {!foodLoading && !movieLoading ? <Navigation /> : <DashBoardPreview />}
      </div>
      <Footer />
    </div>
  )
}

export default App
