import { useEffect, useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";

const TICKET_TYPES = [
  {
    code: "0000000000",
    name: "Standard",
  },
  {
    code: "0000000002",
    name: "Recliner",
  },
];
const TicketSelection = (props) => {
  const { ticketTypes, seatData, setFinalTickets, setBookingFee } = props;

  const [ticketState, setTicektState] = useState({
    standerdTicekts: [],
    reclinerTicektes: [],
  });
  useEffect(() => {
    let _standerdTicekts = [];
    let _reclinerTicektes = [];
    const keys = Object.keys(seatData);
    keys.forEach((rowKey) => {
      const columns = Object.keys(seatData[rowKey]);
      columns.forEach((columnId) => {
        if (seatData[rowKey][columnId].OriginalStatus === 99) {
          if (
            seatData[rowKey][columnId].AreaCategoryCode === TICKET_TYPES[0].code
          ) {
            _standerdTicekts.push({
              ...seatData[rowKey][columnId],
              rowId: rowKey,
            });
          } else {
            _reclinerTicektes.push({
              ...seatData[rowKey][columnId],
              rowId: rowKey,
            });
          }
        }
      });
    });

    setTicektState({
      standerdTicekts: _standerdTicekts,
      reclinerTicektes: _reclinerTicektes,
    });
  }, []);

  const { standerdTicekts, reclinerTicektes } = ticketState;

  const setTicketType = (code, TicketTypeCode, price) => {
    let tickets =
      code === TICKET_TYPES[0].code
        ? [...standerdTicekts]
        : [...reclinerTicektes];

    let isTicketSet = false;
    tickets = tickets.map((ticket) => {
      if (!ticket.TicketTypeCode && isTicketSet === false) {
        ticket.TicketTypeCode = TicketTypeCode;
        ticket.price = price;
        isTicketSet = true;
      }
      return ticket;
    });
    if (code === TICKET_TYPES[0].code) {
      setTicektState({
        ...ticketState,
        standerdTicekts: tickets,
      });
    } else {
      setTicektState({
        ...ticketState,
        reclinerTicektes: tickets,
      });
    }
  };

  const handleRemove = (code, TicketTypeCode, price) => {
    let tickets =
      code === TICKET_TYPES[0].code
        ? [...standerdTicekts]
        : [...reclinerTicektes];

    let isTicketSet = false;

    tickets = tickets.map((ticket) => {
      if (ticket.TicketTypeCode === TicketTypeCode && isTicketSet === false) {
        ticket.TicketTypeCode = "";
        ticket.price = 0;
        isTicketSet = true;
      }
      return ticket;
    });
    if (code === TICKET_TYPES[0].code) {
      setTicektState({
        ...ticketState,
        standerdTicekts: tickets,
      });
    } else {
      setTicektState({
        ...ticketState,
        reclinerTicektes: tickets,
      });
    }
  };

  const BOOKING_FEE = 1.75;

  const totalPrice = useMemo(() => {
    let price = 0;
    let totalSeats = 0;
    reclinerTicektes.forEach((ticket) => {
      if (ticket.price) {
        price += ticket.price;
        totalSeats += BOOKING_FEE;
      }
    });

    standerdTicekts.forEach((ticket) => {
      if (ticket.price) {
        price += ticket.price;
        totalSeats += BOOKING_FEE;
      }
    });

    setBookingFee(totalSeats);

    return {
      total: (price / 100).toFixed(2),
      booking_fee: totalSeats.toFixed(2),
    };
  }, [reclinerTicektes, standerdTicekts, setBookingFee]);

  const renderTypes = (TICKET_TYPE) => {
    const _types = ticketTypes.filter(
      (ticketType) => ticketType.AreaCategoryCode === TICKET_TYPE.code
    );
    let assignCount = 0;

    const tickets =
      TICKET_TYPE.code === TICKET_TYPES[0].code
        ? [...standerdTicekts]
        : [...reclinerTicektes];
    tickets.forEach((ticket) => {
      if (ticket.TicketTypeCode) {
        assignCount++;
      }
    });
    return (
      <div className="box-container">
        <Row className="price-header">
          <Col>{TICKET_TYPE.name} </Col>
          <Col className="col-auto">
            {assignCount}/
            {TICKET_TYPE.code === TICKET_TYPES[0].code
              ? standerdTicekts.length
              : reclinerTicektes.length}
          </Col>
        </Row>
        {_types?.map((ticket) => {
          const canAddMoreTickets = assignCount < tickets.length;
          let assignedCategoryCount = 0;

          tickets.forEach((_ticket) => {
            if (_ticket.TicketTypeCode === ticket.TicketTypeCode) {
              assignedCategoryCount++;
            }
          });
          return (
            <Row
              className="ticket-type"
              key={`${ticket?.AreaCategoryCode}-${ticket?.TicketTypeCode}`}
            >
              <Col>
                <div className="ticket-desc-text">{ticket?.Description}</div>
                <div className="price-text">
                  ${(ticket?.PriceInCents / 100).toFixed(2)}
                </div>
              </Col>
              <Col className="col-auto">
                {assignedCategoryCount >= 1 ? (
                  <Row>
                    <Col>
                      <img
                        alt="minus-button"
                        src={require("../Assets/Images/minus-btn.png")}
                        height={20}
                        width={20}
                        onClick={() =>
                          handleRemove(
                            TICKET_TYPE.code,
                            ticket?.TicketTypeCode,
                            ticket?.PriceInCents
                          )
                        }
                      />
                    </Col>
                    <Col className="quntity-text">{assignedCategoryCount}</Col>
                    <Col>
                      <img
                        alt="minus-button"
                        src={require("../Assets/Images/plus-btn.png")}
                        height={20}
                        width={20}
                        onClick={() => {
                          setTicketType(
                            TICKET_TYPE.code,
                            ticket?.TicketTypeCode,
                            ticket?.PriceInCents
                          );
                        }}
                      />
                    </Col>
                  </Row>
                ) : canAddMoreTickets ? (
                  <Button
                    size="small"
                    onClick={() =>
                      setTicketType(
                        TICKET_TYPE.code,
                        ticket?.TicketTypeCode,
                        ticket?.PriceInCents
                      )
                    }
                  >
                    Add
                  </Button>
                ) : null}
              </Col>
            </Row>
          );
        })}
      </div>
    );
  };

  const isAllNormalAlocated =
    standerdTicekts.filter((seat) => !seat.price).length === 0;
  const isAllReclinerAlocated =
    reclinerTicektes.filter((seat) => !seat.price).length === 0;
  return (
    <div className="booking-conformation">
      <div className="booking-container">
        {TICKET_TYPES.map((TICKET_TYPE) => {
          return <div key={TICKET_TYPE.code}>{renderTypes(TICKET_TYPE)}</div>;
        })}
      </div>
      <div className="d-grid booking-button">
        <Button
          disabled={!isAllNormalAlocated || !isAllReclinerAlocated}
          size="smal"
          onClick={() => setFinalTickets([standerdTicekts, reclinerTicektes])}
          className="price-button"
        >
          Proceed ${totalPrice.total} +&nbsp;{" "}
          <span className="booking-fee-text">Booking Fee</span> &nbsp; $
          {totalPrice.booking_fee}
        </Button>
      </div>
    </div>
  );
};

export default TicketSelection;
