/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
//Import librarys
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useLocation } from 'react-router-dom'

//Import CSS
import '../Styles/common.css'
import '../Styles/header.css'

//Import Components
import Login from '../pages/Login'
import logo from '../Assets/Images/FoMoLogo_White_NoBackground.png'
import {
  loginUsingToken,
  logoutUser,
  setShowLogin,
} from '../store/slices/authSlice'
import { auth } from '../utils/firebaseApp'
import { clientService } from '../utils/Service'

//Header Components
const Header = ({ foodData }) => {
  const dispatch = useDispatch()
  const isUserLoggedIn = useSelector((state) => state.auth.isLoggedn)
  const isUserDetail = useSelector((state) => state.auth.authUser)
  const showModal = useSelector((state) => state.auth.showLogin)

  const [showUserModal, setShowUserModal] = useState(false)
  const [formData, setFormdata] = useState({
    title: '',
    name: '',
    email: '',
  })

  const dropdownData = [
    {
      id: 1,
      title: 'Senor (Sr)',
    },
    {
      id: 2,
      title: 'Miss (MS)',
    },
    {
      id: 3,
      title: 'The Honourable (TH)',
    },
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [showModal])

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (!!authUser) {
        dispatch(
          loginUsingToken({
            token: auth.currentUser.auth.currentUser.accessToken,
          }),
        )
      }
    })
  }, [])

  useEffect(() => {
    if (isUserDetail.id && (!isUserDetail.name || !isUserDetail.email)) {
      setShowUserModal(true)
    }
  }, [isUserDetail])

  const handleLogin = () => {
    dispatch(setShowLogin(false))
  }

  const handleLogout = async () => {
    try {
      await auth.signOut()
      dispatch(logoutUser())
      window.location.href = '/'
    } catch (error) {
      console.error('Error during logout:', error)
    }
  }

  const handleLoginModal = () => {
    dispatch(setShowLogin(true))
  }

  const handleCloseModal = () => {
    dispatch(setShowLogin(false))
  }

  const handleChange = (e) => {
    setFormdata({ ...formData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const res = await clientService.post('front/user/profile', formData)
      if (res.status) {
        setShowUserModal(false)
        alert('Profile has been updated.')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const location = useLocation()
  if (location.search === '?mobile') {
    return ''
  }

  const handleSubscribeClick = () => {
    window.location.href =
      'https://fomocinemas.us9.list-manage.com/subscribe?u=bdc255b6bd0f13c2f350b28b5&id=9d95af19c4'
    // // Scroll to the bottom of the page
    // const scrollHeight = Math.max(
    //   document.body.scrollHeight,
    //   document.documentElement.scrollHeight,
    // )
    // window.scrollTo(0, scrollHeight)
    // const footerElement = document.getElementById('footer')
    // if (footerElement) {
    //   footerElement.scrollIntoView({ behavior: 'smooth' })
    // }
  }

  return (
    <>
      <nav
        className="navbar navbar-expand-lg .bg-dark.bg-gradient header sticky-top"
        data-bs-theme="dark"
      >
        <div className="container d-flex align-item-center justify-content-between">
          <div className="navbar-brand text-white">
            <span>
              <Link to="/" className="nav-link active" aria-current="page">
                <img src={logo} height={70} width={150} alt="Fomo" />
              </Link>
            </span>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasDarkNavbar"
            aria-controls="offcanvasDarkNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end text-bg-dark"
            tabIndex="-1"
            id="offcanvasDarkNavbar"
            aria-labelledby="offcanvasDarkNavbarLabel"
          >
            <div className="offcanvas-header">
              <div className="navbar-brand text-white">
                <span>
                  <Link to="/" className="nav-link active" aria-current="page">
                    <img
                      src={logo}
                      height={60}
                      width={130}
                      data-bs-dismiss="offcanvas"
                    />
                  </Link>
                </span>
              </div>
              <button
                type="button"
                className="btn-close btn-close-white"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                style={{ marginRight: 17, marginBottom: 5 }}
              ></button>
            </div>
            <div className="offcanvas-body justify-content-end align-items-center">
              <div className="me-3 offcanvas-body">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 ">
                  <li className="nav-item" data-bs-dismiss="offcanvas">
                    <NavLink
                      // exact
                      to={{
                        pathname: '/now-showing',
                        state: {},
                      }}
                      className="nav-link"
                      // activeClassName="active-tab"
                      state={''}
                    >
                      Now Showing
                    </NavLink>
                  </li>
                  <li className="nav-item" data-bs-dismiss="offcanvas">
                    <NavLink
                      // exact
                      to="/cooming-soon"
                      className="nav-link"
                      // activeClassName="active-tab"
                    >
                      Coming Soon
                    </NavLink>
                  </li>
                  <li className="nav-item" data-bs-dismiss="offcanvas">
                    <NavLink
                      // exact
                      to="/food"
                      className="nav-link"
                      // activeClassName="active-tab"
                    >
                      Food
                    </NavLink>
                  </li>

                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle"
                      to="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      More
                    </Link>
                    <ul
                      className="dropdown-menu dropdown-menu-dark"
                      aria-labelledby="navbarDropdown"
                    >
                      <li data-bs-dismiss="offcanvas">
                        <Link className="dropdown-item" to="/about">
                          About
                        </Link>
                      </li>
                      <li data-bs-dismiss="offcanvas">
                        <Link className="dropdown-item" to="/faq">
                          FAQs
                        </Link>
                      </li>

                      <li data-bs-dismiss="offcanvas">
                        <Link className="dropdown-item" to="/contact-us">
                          Contact Us
                        </Link>
                      </li>
                      {/* <li data-bs-dismiss="offcanvas">
                        <Link className="dropdown-item" to="/gift-card">
                          Gift Card
                        </Link>
                      </li> */}
                      <li data-bs-dismiss="offcanvas">
                        <Link className="dropdown-item" to="/disclaimer">
                          Terms & Conditions
                        </Link>
                      </li>
                      <li data-bs-dismiss="offcanvas">
                        <Link className="dropdown-item" to="/privacy-policy">
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item" data-bs-dismiss="offcanvas">
                    {isUserLoggedIn === false ? (
                      <div
                        className="nav-link"
                        onClick={() => {
                          handleLoginModal()
                        }}
                      >
                        Login
                      </div>
                    ) : (
                      <NavLink
                        // exact
                        to="/profile"
                        className="nav-link"
                      >
                        <img
                          src={require('../Assets/Images/profileUser.png')}
                          height={25}
                          width={25}
                          className="white-image"
                        />
                      </NavLink>
                    )}
                  </li>
                </ul>
              </div>

              <div className="d-flex  justify-content-start align-items-center mx-2">
                <Link
                  to="https://www.facebook.com/fomo.cinemas.east.brunswick"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require('../Assets/Images/facebook-new.png')}
                    height={25}
                    width={25}
                    className="white-image"
                    data-bs-dismiss="offcanvas"
                  />
                </Link>

                <Link
                  to="https://www.instagram.com/fomocinemas/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require('../Assets/Images/instagram.png')}
                    height={25}
                    width={25}
                    className="white-image instagram-view"
                    data-bs-dismiss="offcanvas"
                  />
                </Link>
                <Link
                  to="https://www.tiktok.com/@fomocinemas"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require('../Assets/Images/tik-tok.png')}
                    height={25}
                    width={25}
                    className="white-image"
                    data-bs-dismiss="offcanvas"
                  />
                </Link>
                <Link
                  to="mailto:mail@fomocinemas.com.au"
                  // target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={require('../Assets/Images/Email_icon.png')}
                    height={50}
                    width={50}
                    className="mt-1"
                    data-bs-dismiss="offcanvas"
                  />
                </Link>
              </div>

              <div>
                <button
                  className="subscribe-btn mobile-hidden-view"
                  onClick={handleSubscribeClick}
                  data-bs-dismiss="offcanvas"
                >
                  Subscribe to our eNews
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className="modal-view"
        backdrop={false}
      >
        <div className="modal-content">
          <Modal.Header closeButton closeVariant="white" className="bg-black">
            <Modal.Title className="text-white">Login</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-black">
            <Login onLoginSuccess={handleLogin} />
          </Modal.Body>
        </div>
      </Modal>

      <Modal
        show={showUserModal}
        onHide={() => setShowUserModal(false)}
        className="profile-modal-view"
      >
        <div className="profile-modal-content">
          <Modal.Header closeButton closeVariant="white" className="bg-black">
            <Modal.Title className="text-white">Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-black profile-body">
            <div>
              {/* {!isUserDetail.email && !isUserDetail.name ? ( */}
              <form onSubmit={handleSubmit} className="mt-3">
                <select
                  name="title"
                  className="input"
                  value={formData.title}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Select Title
                  </option>
                  {dropdownData.map((item) => (
                    <option value={item.title} key={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
                <div>
                  <input
                    type="text"
                    name="name"
                    placeholder="Name"
                    className="input"
                    value={formData.name}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email address"
                    className="input"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <button type="submit" className="profile-btn-view">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  )
}

export default Header
